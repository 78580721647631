/**
 * Location Types (Door and Zone)
 */

import i18n from '../i18n';

const DOOR = 'DOOR';
const ZONE = 'ZONE';

const locationTypes = {
  door: DOOR,
  zone: ZONE,
};

function getLocationTypeReferences(quantity) {
  const result = [];
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  result.push({ value: DOOR, label: i18n.tc('locationType.door', qty) });
  result.push({ value: ZONE, label: i18n.tc('locationType.zone', qty) });
  return result;
}

function getLocationTypeLabel(locationTypeValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (locationTypeValue === DOOR) return i18n.t('locationType.door', qty);
  if (locationTypeValue === ZONE) return i18n.t('locationType.zone', qty);
  throw TypeError(`Location Type value expected, read ${locationTypeValue}.`);
}

export default locationTypes;
export {
  locationTypes,
  DOOR,
  ZONE,
  getLocationTypeReferences,
  getLocationTypeLabel,
};
