<template>
  <v-data-table
  class="elevation-1"
  :expanded.sync="expanded"
  :headers="headers"
  :items="items"
  :item-class="setRowClass"
  :items-per-page="itemsPerPage"
  :loading.sync="loading"
  :isListGetAll.sync="isListGetAll"
  :single-expand="singleExpand"
  :isItemUpdatable="isItemUpdatable"
  :sort-by="sortBy"
  :sort-desc="sortDesc"
  :search="search"
  multi-sort
  show-expand
  @click:row="rowClicked"
  >

  <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-btn
        class="mx-4"
        small
        text
        @click="refreshData"
      >
        <v-icon class="mx-2">mdi-refresh</v-icon>
        {{ crudLabels.list }}
      </v-btn>

      <v-switch
        v-if="crudAccess.readInactive"
        class="pt-6"
        v-model="isListGetAllDisplayed"
        :label="isListGetAllLabel"
        >
      </v-switch>

      <v-dialog
        v-model="dialog"
        max-width="75%"
      >
        <template
          v-if="crudAccess.create"
          v-slot:activator="{ on, attrs }">
          <v-btn v-if="usesDefaultForm"
            class="mx-4"
            color="primary"
            small
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon  class="mx-2">mdi-playlist-plus</v-icon>
            {{ crudLabels.create }}
          </v-btn>
          <v-btn v-else
            class="mx-4"
            color="primary"
            small
            text
            @click="navigateToFormInsert"
          >
            <v-icon  class="mx-2">mdi-playlist-plus</v-icon>
            {{ crudLabels.create }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
            <v-spacer></v-spacer>
              <template
                v-for="action in otherItemActions"
                >
                <v-btn :key="action.id"
                  v-if="isDisplayedOtherItemAction(editedItem, action)"
                  small
                  class="mx-4"
                  text
                  :color="action.btnColor || 'primary'"
                  @click.stop="emitOtherItemActionEvent(editedItem, action.event)"
                >
                  <v-icon class="mx-2">{{ action.icon }}</v-icon>
                  {{ action.text }}
                </v-btn>
              </template>

          </v-card-title>

          <v-card-text>
            <v-container>
              <!-- Editable form: begin -->
              <v-form ref="form" v-model="valid" dense>
              <!-- Main Section Fields -->
              <v-row>
                <v-col
                  cols="12" sm="12" md="12"
                  dense
                  v-for="key in modelMainSectionFields"
                  v-bind:key="key.name"
                >
                  <v-select v-if="key.references "
                    v-model="editedItem[key.name]"
                    :label="key.label"
                    :rules="key.rules"
                    :hint="key.hint"
                    persistent-hint
                    :readonly="isFieldReadOnly(key)"
                    :disabled="isFieldReadOnly(key)"
                    :items="key.references"
                    item-text="label"
                    item-value="value"
                    clearable
                    clear-icon="mdi-eraser"
                    dense
                    :type="templateFieldType(key)"
                  ></v-select>
                  <v-text-field v-else-if="!key.join"
                    v-model="editedItem[key.name]"
                    :label="key.label"
                    :rules="key.rules"
                    :hint="key.hint"
                    persistent-hint
                    :readonly="isFieldReadOnly(key)"
                    :disabled="isFieldReadOnly(key)"
                    clearable
                    clear-icon="mdi-eraser"
                    dense
                    :type="templateFieldType(key)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Sub sections (fieds stored in sub arrays of the model ) -->
              <v-container
                v-for="section in modelSubSections"
                v-bind:key="section.name"
                >
                  <v-row cols="12" sm="12" md="12">
                    <span class="text-h6">{{ section.label }}</span>
                  </v-row>
                  <!-- Sub section fields -->
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-for="field in section.fields"
                      v-bind:key="field.name"
                    >
                      <v-text-field
                        v-model="editedItem[section.name][field.name]"
                        :value="formatValue(item, field)"
                        :label="field.label"
                        :rules="field.rules"
                        :hint="key.hint"
                        persistent-hint
                        :readonly="isFieldReadOnly(key)"
                        :disabled="isFieldReadOnly(key)"
                        clearable
                        clear-icon="mdi-eraser"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
              </v-container>

              <!-- Simili section for joins/relationships -->
              <!-- Same code as expansion panel (with editedItem as data source)-->
              <v-container
                dense
                v-for="field in modelMainSectionJoinFields"
                v-bind:key="field.name"
                >
                  <v-row dense  v-if="editedItem.joins">
                    <v-col cols="12" sm="12" md="12">
                      <v-icon color="primary" @click="joinedItemClickNew(field.join, item)"
                      >
                        mdi-playlist-plus
                      </v-icon>
                      {{ field.label }}
                    </v-col>
                  </v-row>
                  <v-row dense v-if="editedItem.joins">
                    <v-col cols="12" sm="12" md="12"
                      v-for="dataItem in editedItem.joins[field.join.name].data"
                      v-bind:key="dataItem.key"
                    >
                      <v-chip label @click="joinedItemClick(dataItem)">
                        <v-icon v-if="dataItem.icon"
                          small class="mx-2"
                          @click="joinedItemClick(dataItem)"
                        > {{ dataItem.icon }}
                        </v-icon>
                        {{ dataItem.displayText }}
                      </v-chip>
                    </v-col>
                  </v-row>
              </v-container>

              </v-form>
              <!-- Editable form: end -->
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              {{ $t("appButton.cancel") }}
            </v-btn>

            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="!valid"
            >
              {{ $t("appButton.save") }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="800px">
        <v-card>
          <v-card-title class="stong">{{ crudLabels.confirmDelete }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >{{ $t("appButton.cancel") }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >{{ $t("appButton.delete") }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        clear-icon="mdi-eraser"
      ></v-text-field>

    </v-toolbar>
  </template>

  <template v-slot:[`item.actions`]="{ item }">
    <v-icon v-if="isItemUpdatable(item)"
      small
      class="mr-2"
      @click="editItem(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon v-if="crudAccess.delete"
      small
      class="mr-2"
      @click="deleteItem(item)"
    >
      mdi-delete
    </v-icon>
    <v-icon v-if="!isItemUpdatable(item) && !crudAccess.delete"
      small
      class="mr-2"
    >
      mdi-magnify
    </v-icon>
    <template
      v-for="action in otherItemActions"
      >
      <v-icon
      :key="action.id"
      v-if="isDisplayedOtherItemAction(item, action)"
      small
      class="mr-2"
      :color="otherItemActionIconColor(item, action)"
      @click.stop="emitOtherItemActionEvent(item, action.event)"
      >
        {{ action.icon }}
      </v-icon>
      <v-icon
      :key="action.id"
      v-else
      small
      disabled
      class="mr-2"
      >
        mdi-block-helper
      </v-icon>
    </template>

  </template>

  <template v-slot:[`item.isActive`]="{ item }">
    <v-simple-checkbox
      v-model="item.isActive" disabled class="ms-6"
    ></v-simple-checkbox>
  </template>
  <template v-slot:[`item.badgeIsActive`]="{ item }">
    <v-simple-checkbox
      v-model="item.badgeIsActive" disabled class="ms-6"
    ></v-simple-checkbox>
  </template>
  <template v-slot:[`item.companyIsActive`]="{ item }">
    <v-simple-checkbox
      v-model="item.companyIsActive" disabled class="ms-6"
    ></v-simple-checkbox>
  </template>
  <template v-slot:[`item.stationIsActive`]="{ item }">
    <v-simple-checkbox
      v-model="item.stationIsActive" disabled class="ms-6"
    ></v-simple-checkbox>
  </template>
  <template v-slot:[`item.userIsActive`]="{ item }">
    <v-simple-checkbox
      v-model="item.userIsActive" disabled class="ms-6"
    ></v-simple-checkbox>
  </template>

  <template v-slot:[`item.capacity`]="{ item }">
    <v-list-item-content>
       {{ getCapacityLabel(item) }}
    </v-list-item-content>
  </template>

  <template v-slot:[`item.role`]="{ item }">
    <v-list-item-content>
       {{ getUserRoleLabel(item.role) }}
    </v-list-item-content>
  </template>

  <template v-slot:[`item.ruleActivationWeekDays`]="{ item }">
    <v-list-item-content>
       {{ getWeekDaysArrayLabel(item.ruleActivationWeekDays) }}
    </v-list-item-content>
  </template>

  <template v-slot:[`item.userIdList`]="{ item }">
    <v-list-item-content>
       {{ getSelectSummaryLabel(item.userIdList) }}
    </v-list-item-content>
  </template>

  <template v-slot:[`item.locationIdList`]="{ item }">
    <v-list-item-content>
       {{ getSelectSummaryLabel(item.locationIdList) }}
    </v-list-item-content>
  </template>

  <template v-slot:[`item.join`]="{ item }">
    <!-- display all expected join, i.e. with an defined key in keys -->
    <span
      v-for="key in item.joins.keys"
      v-bind:key="key"
     >
       <!-- The join key is retrieved, get the data build some chips -->
       <div v-for="dataItem in item.joins[key].data"
         v-bind:key="dataItem.key"
       >
        <v-chip
          outlined label
          :color="dataItem.color"
          :text-color="dataItem.textColor"
          @click="joinedItemClick(dataItem)"
        >
        <!-- text-color="grey darken-2" -->
          <v-icon v-if="dataItem.icon"
            small
            class="mr-2"
          > {{ dataItem.icon }}
          </v-icon>
          {{ dataItem.displayText }}
        </v-chip>
       </div>
    <!-- {{ formatValueFromModel(item, 'type') }} -->
     </span>
  </template>

  <!-- Row expansion panel: begin -->
  <template
    v-slot:expanded-item="{ headers, item }"
    flat
  >
    <td :colspan="headers.length" class="blue lighten-5">
      <!-- Main section (fieds stored at the root of the model) -->
      <v-row>
        <v-col cols="12" sm="12" md="12"
          v-for="field in modelMainSectionFieldsWithoutJoin"
          v-bind:key="field.name"
          @click="editItem(item)"
        >
          <v-text-field v-if="field.type !== 'stringArray'"
            :value="formatValue(item, field)"
            :label="field.label"
            readonly
            hide-details
          ></v-text-field>
          <v-textarea v-if="field.type === 'stringArray'"
            :value="formatValue(item, field)"
            :label="field.label"
            readonly
            hide-details
            auto-grow
            rows=1
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Joins/relationships (from modelMainSectionFields, but visually separated in the form) -->
      <v-container
        dense
        v-for="field in modelMainSectionJoinFields"
        v-bind:key="field.name"
        >
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-icon color="primary" @click="joinedItemClickNew(field.join, item)"
              >
                mdi-playlist-plus
              </v-icon>
              {{ field.label }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12"
              v-for="dataItem in item.joins[field.join.name].data"
              v-bind:key="dataItem.key"
            >
              <v-chip label @click="joinedItemClick(dataItem)">
                <v-icon v-if="dataItem.icon"
                  small class="mx-2"
                  @click="joinedItemClick(dataItem)"
                > {{ dataItem.icon }}
                </v-icon>
                {{ dataItem.displayText }}
              </v-chip>
            </v-col>
          </v-row>
      </v-container>

      <!-- Sub sections (fieds stored in sub arrays of the model ) -->
      <v-container
        v-for="section in modelSubSections"
        v-bind:key="section.name"
        >
          <!-- Sub section name () -->
          <v-row><v-col cols="12" sm="12" md="12">
            <v-chip
              color="primary" label link
              :outlined=isSectionDisplayed(section.name)
              @click="toggledDisplayedSection(section.name)"
            >{{ section.label }}</v-chip>
          </v-col></v-row>

          <!-- Sub section fields -->
          <v-row v-if="isSectionDisplayed(section.name)">
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-for="field in section.fields"
              v-bind:key="field.name"
            >
              <v-text-field
                v-model="item[section.name][field.name]"
                :label="field.label"
                readonly
                @click="editItem(item)"
              ></v-text-field>
            </v-col>
          </v-row>
      </v-container>

    </td>
  </template>
  <!-- Row expansion panel: end -->

  </v-data-table>
</template>

<script>
import rules from '@/model/validationRules';
import { mapGetters } from 'vuex';
import {
  datatypes,
  baseDatatypes,
  reservedDatatypes,
  getBooleanLabel,
  getDoorCapacityLabel,
  getTemplateFieldType,
  getTimestampLabel,
  getSelectSummaryLabel,
} from '@/model/datatypes';
import { itemStatusValues, getItemStatusLabel } from '@/model/itemStatus';
import { locationTypes } from '@/model/locationType';
import { getUserRoleLabel } from '@/model/userRole';
import { getWeekDaysArrayShortLabel } from '@/model/weekDays';
import { urlFormatter } from '@/router/index';
import { isFieldAllowedForRolesRead, isFieldAllowedForRolesWrite } from '@/model/accessRolesModel';
import i18n from '../i18n';

export default {
  name: 'EditableDataTable',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    displayedSections: [],
    editedIndex: -1,
    editedItem: {},
    expanded: [],
    rules,
    search: '',
    singleExpand: true,
    valid: true,
  }),
  props: {
    crudAccess: {
      type: Object,
      required: true,
    },
    crudLabels: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    loading: Boolean,
    specificFormURL: {
      type: String,
      required: false,
      default: '',
    },
    isListGetAll: {
      required: false,
      default: false,
    },
    isItemUpdatable: {
      required: false,
      default: true,
    },
    itemsPerPage: {
      required: false,
      default: 15,
    },
    otherItemActions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    sortBy: {
      default: 'name', // "['calories', 'fat']"
    },
    sortDesc: {
      default: true, // "[false, true]"
    },
  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),

    defaultItem() {
      return this.getDefaultItem();
    },

    formTitle() {
      return this.editedItem.id ? this.crudLabels.update : this.crudLabels.create;
    },

    isListGetAllDisplayed: {
      get() {
        return this.isListGetAll;
      },
      set() {
        this.$emit('switchIsGetListAll');
      },
    },

    isListGetAllLabel() {
      if (this.isListGetAll) return i18n.tc('crudLabels.listAll', 1);
      return i18n.tc('crudLabels.listActive', 1);
    },

    modelMainSectionFields() {
      const result = this.model.filter((dd) => (dd.type in baseDatatypes));
      return result;
    },

    modelMainSectionFieldsWithoutJoin() {
      const result = this.model.filter((field) => (field.type in baseDatatypes && !field.join));
      // console.log('modelMainSectionFields', result);
      return result;
    },

    modelMainSectionJoinFields() {
      const result = this.model.filter((field) => (field.type in baseDatatypes && field.join));
      // console.log('modelMainSectionFields', result);
      return result;
    },

    modelSubSections() {
      const result = this.model.filter((dd) => (dd.type === reservedDatatypes.fieldArray));
      // console.log('subsections', result);
      return result;
    },

    usesDefaultForm() {
      return !(this.specificFormURL && this.specificFormURL?.length > 0) ?? false;
    },
  },

  watch: {
    dialog(val) {
      if (val && 'id' in this.editedItem === false) {
        this.editedItem = this.getDefaultItem();
        this.editedIndex = -1;
      }
      return val || this.close();
    },
    dialogDelete(val) {
      return val || this.closeDelete();
    },
    model() {
      this.resetData();
    },
  },

  created() {
    this.resetData();
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        const initialIndex = this.editedIndex;
        this.editedItem = this.getDefaultItem();
        this.editedIndex = -1; // Why not keep the item selected in the list ?
        this.$refs.form.resetValidation();
        this.rowClicked(this.items[initialIndex]);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = this.getDefaultItem();
        this.editedIndex = -1;
        // this.editedIndex -= 1; // TODO : TESTIT : we get to the previous one.
        // if (this.editedIndex > -1) {
        //   this.editedItem = this.items[this.editedIndex];
        // } else {
        //   this.editedItem = this.getDefaultItem();
        // }
      });
    },

    deleteItem(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit('delete', this.editedItem.id);
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    editItem(item) {
      if (this.isItemUpdatable(item)) {
        this.editedIndex = this.items.indexOf(item);
        // this.editedItem = { ...item };  Use Deep copy with JSON conversion
        // (otherwise : bug with sub sections fields).
        this.editedItem = JSON.parse(JSON.stringify(item));
        if (this.usesDefaultForm) {
          this.dialog = true;
        } else {
          this.navigateToFormUpdate(item.id);
        }
      } else {
        this.$store.dispatch('displayInfo', this.$tc('errMsg.readOnlyData', 1));
      }
    },

    emitOtherItemActionEvent(item, emittedEdvent) {
      this.$emit(emittedEdvent, item);
    },

    findModelFieldByName(name) {
      const result = this.model.find((item) => item.name === name);
      return result;
    },

    formatJoinValueExpanded(item, field) {
      console.log('formatJoinValueExpanded.item', item);
      console.log('formatJoinValueExpanded.field', field);
      if (field.name && field.name === 'join' && field.join && field.join.name) {
        const joinName = field.join.name;
        return joinName;
      }
      // In case we were not in the expected case, return an empty string
      return '';
    },

    formatValue(item, field) {
      let result = item[field.name];
      if (field?.valueFormat?.method) {
        result = field.valueFormat.method(item[field.name]);
      } else if (field.type === datatypes.boolean) {
        result = getBooleanLabel(item[field.name]);
      } else if (field.type === datatypes.timestamp) {
        result = getTimestampLabel(item[field.name]);
      } else if (field.type === datatypes.itemStatus) {
        result = getItemStatusLabel(item[field.name]);
      } else if (field.references) {
        const found = field.references.find((ref) => ref.value === result);
        if (found && found.label) result = found.label;
      }
      return result;
    },

    formatValueFromModel(item, name) {
      const field = this.findModelFieldByName(name);
      if (field) return this.formatValue(item, field);
      return item[name];
    },

    getDefaultItem() {
      const result = {};
      // Main section fields
      this.modelMainSectionFields.forEach((field) => {
        result[field.name] = field?.default ?? null;
      });
      this.modelSubSections.forEach((sub) => {
        result[sub.name] = {};
        sub.fields.forEach((field) => {
          result[sub.name][field.name] = field?.default ?? null;
        });
      });
      return result;
    },

    isDisplayedOtherItemAction(item, itemAction) {
      return itemAction?.isDisplayed ? itemAction?.isDisplayed(item) : true;
    },

    isFieldReadOnly(field) {
      return isFieldAllowedForRolesRead(field, this.userRoles)
             && !isFieldAllowedForRolesWrite(field, this.userRoles)
             && this.editedIndex > -1;
    },

    isSectionDisplayed(sectionName) {
      return this.displayedSections.indexOf(sectionName) > -1;
    },

    joinedItemClick(dataItem) {
      if (dataItem.onClickUrl) this.$router.push(dataItem.onClickUrl);
    },

    joinedItemClickNew(join, item) {
      // deviceLocationNew
      if (join.urlFormatterNewJoinMethodName) {
        const url = urlFormatter[join.urlFormatterNewJoinMethodName](item.id);
        console.log(url);
        this.$router.push(url);
        return url;
      }
      return ''; // fallback
    },

    navigateToFormInsert() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      const url = `${this.specificFormURL}new`;
      // console.log(url);
      this.$router.push(url);
      return url;
    },

    navigateToFormUpdate(itemId) {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      const url = `${this.specificFormURL}edit/${encodeURIComponent(itemId)}`;
      // console.log(url);
      this.$router.push(url);
      return url;
    },

    otherItemActionIconColor(item, itemAction) {
      if (itemAction?.iconColor) return itemAction.iconColor;
      if (itemAction?.getIconColor) return itemAction.getIconColor(item, itemAction);
      return '';
    },

    refreshData() {
      if (this.dialog) {
        this.close();
      }
      this.resetData();
      this.$emit('refreshData');
    },

    resetData() {
      this.dialog = false;
      this.dialogDelete = false;
      this.editedIndex = -1;
      this.editedItem = this.getDefaultItem();
      this.expanded = [];
    },

    rowClicked(row) {
      const rowIndex = this.items.indexOf(row);
      if (this.editedIndex === rowIndex && this.dialog === false) {
        // Close the expanded row (we suppose this is an expected behaviour on a volontary click)
        this.expanded = [];
        this.editedIndex = -1;
      } else {
        this.expanded = [row];
        this.editedIndex = rowIndex;
      }
    },

    setRowClass(item) {
      if (this.editedIndex === this.items.indexOf(item)) return 'blue lighten-4';
      if (item.itemStatus === itemStatusValues.inactive) return 'blue-grey lighten-4';
      return '';
    },

    save() {
      try {
        const item = JSON.parse(JSON.stringify(this.editedItem));
        if (this.editedIndex === -1) {
          this.$emit('create', item);
        } else {
          this.$emit('update', item);
        }
      } finally {
        // "create" and "update" event handlers should efficiently handle their own exception
        // => No catch excetpion here.
        this.$refs.form.reset();
        this.close();
      }
    },

    templateFieldType(field) {
      return getTemplateFieldType(field.type);
    },

    toggledDisplayedSection(sectionName) {
      if (this.isSectionDisplayed(sectionName)) {
        this.displayedSections.splice(this.displayedSections.indexOf(sectionName), 1);
      } else {
        this.displayedSections.push(sectionName);
      }
    },

    // TODO : Move this away ! This is an awful way to code....
    getCapacityLabel(item) {
      if (item?.locationType === locationTypes.door) {
        return getDoorCapacityLabel(item.capacity);
      }
      return item.capacity;
    },

    getUserRoleLabel(role) {
      return getUserRoleLabel(role);
    },

    getWeekDaysArrayLabel(weekDaysArray) {
      return getWeekDaysArrayShortLabel(weekDaysArray);
    },

    getSelectSummaryLabel(userIdList) {
      return getSelectSummaryLabel(userIdList);
    },

  },
  components: {
  },
};
</script>

<style>
.text-start {
  text-transform:capitalize;
}

.pre {
  white-space: pre-line;
}

</style>
