/**
 * Rule Access Mode
 */

import i18n from '../i18n';

const MONDAY = 'Monday';
const TUESDAY = 'Tuesday';
const WEDNESDAY = 'Wednesday';
const THURSDAY = 'Thursday';
const FRIDAY = 'Friday';
const SATURDAY = 'Saturday';
const SUNDAY = 'Sunday';

const weekDays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

function getWeekDayLabel(weekDayValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (weekDayValue === MONDAY) return i18n.tc('weekDay.MONDAY', qty);
  if (weekDayValue === TUESDAY) return i18n.tc('ruleAccessMode.TUESDAY', qty);
  if (weekDayValue === WEDNESDAY) return i18n.tc('ruleAccessMode.WEDNESDAY', qty);
  if (weekDayValue === THURSDAY) return i18n.tc('ruleAccessMode.THURSDAY', qty);
  if (weekDayValue === FRIDAY) return i18n.tc('ruleAccessMode.FRIDAY', qty);
  if (weekDayValue === SATURDAY) return i18n.tc('ruleAccessMode.SATURDAY', qty);
  if (weekDayValue === SUNDAY) return i18n.tc('ruleAccessMode.SUNDAY', qty);
  return weekDayValue;
}

function getWeeKDayLabels(count) {
  const result = [];
  weekDays.forEach((day) => {
    result.push(getWeekDayLabel(day, count));
  });
  return result;
}

function getWeekDayReferences(quantity) {
  const result = [];
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  result.push({ value: MONDAY, label: i18n.tc('weekDay.MONDAY', qty) });
  result.push({ value: TUESDAY, label: i18n.tc('weekDay.TUESDAY', qty) });
  result.push({ value: WEDNESDAY, label: i18n.tc('weekDay.WEDNESDAY', qty) });
  result.push({ value: THURSDAY, label: i18n.tc('weekDay.THURSDAY', qty) });
  result.push({ value: FRIDAY, label: i18n.tc('weekDay.FRIDAY', qty) });
  result.push({ value: SATURDAY, label: i18n.tc('weekDay.SATURDAY', qty) });
  result.push({ value: SUNDAY, label: i18n.tc('weekDay.SUNDAY', qty) });
  return result;
}

function getWeekDayLabelsArray(weekDaysArray, qty) {
  const localQty = qty in [0, 1, 2] ? qty : 1;
  const result = [];
  if (weekDaysArray.indexOf(MONDAY) > -1) result.push(i18n.tc('weekDay.MONDAY', localQty));
  if (weekDaysArray.indexOf(TUESDAY) > -1) result.push(i18n.tc('weekDay.TUESDAY', localQty));
  if (weekDaysArray.indexOf(WEDNESDAY) > -1) result.push(i18n.tc('weekDay.WEDNESDAY', localQty));
  if (weekDaysArray.indexOf(THURSDAY) > -1) result.push(i18n.tc('weekDay.THURSDAY', localQty));
  if (weekDaysArray.indexOf(FRIDAY) > -1) result.push(i18n.tc('weekDay.FRIDAY', localQty));
  if (weekDaysArray.indexOf(SATURDAY) > -1) result.push(i18n.tc('weekDay.SATURDAY', localQty));
  if (weekDaysArray.indexOf(SUNDAY) > -1) result.push(i18n.tc('weekDay.SUNDAY', localQty));
  return result;
}

function getWeekDaysArrayLabel(weekDaysArray) {
  const result = getWeekDayLabelsArray(weekDaysArray);
  return result.join(', ');
}

function getWeekDaysArrayShortLabel(weekDaysArray) {
  const result = getWeekDayLabelsArray(weekDaysArray, 0).map((d) => d.substring(0, 3));
  return result.join(', ');
}

export default weekDays;
export {
  weekDays,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  getWeekDaysArrayLabel,
  getWeekDaysArrayShortLabel,
  getWeekDayLabel,
  getWeeKDayLabels,
  getWeekDayReferences,
};
